import React, { useEffect } from 'react';
import MapCentered from './map';
import { Link, Element, scroller } from 'react-scroll';
import TramSvg from '../svg/tram';
import SmileSvg from '../svg/smile';
import QuestionSvg from '../svg/question';
import EmailSvg from '../svg/email';
import FacebookSvg from '../svg/facebook';
import InstagramSvg from '../svg/instagram';
import TikTokSvg from '../svg/tiktok';
import XTwitterSvg from '../svg/x';
import YouTubeSvg from '../svg/youtube';
import { prefersReducedMotion } from '../lib/helpers';

export interface SimpleCenteredProps {
  /** If defined, this component will attempt to scroll to this element. */
  scrollToElement?: string;
}

/**
 * The landing page's main component that is displayed between the header and
 * footer. It probably should have a better name.
 *
 * Tangential Note: The primary `bg-camelback` `<div>` element has to use an
 * inline style for the `background-image: url()` style as opposed to doing it
 * in `index.css` because React/Webpack, in all its genius, try to bundle the
 * image at compile time. It fails unless the image is under `src/`. Binary
 * blobs meant to be downloaded by users (like jpg files) should live under
 * `public/`, not `src/` to avoid messing with the compiled code footprint,
 * which should always be as small as possible.
 */
const SimpleCentered = (props: SimpleCenteredProps) => {
  const {
    scrollToElement,
  } = props;

  // const [scrolledTo, setScrolledTo] = useState(scrollToElement);

  useEffect(() => {
    if (scrollToElement) {
      scroller.scrollTo(scrollToElement, {
        duration: prefersReducedMotion() ? 0 : 300,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }
  }, []);

  return (
    <div>
      <Element name="part-1" className="element">
        <div className="relative isolate px-8 pt-4 lg:px-8 bg-center bg-no-repeat bg-camelback bg-gray-700 bg-blend-multiply" style={{ backgroundImage: 'url(/img/chris-tingom-BaDrWEr794Q-unsplash.jpg)'}}>
          <div className="mt-2 flex items-end justify-end">
            <a
              href="/tools/data/tax-per-acre"
              target='_blank' rel="noopener noreferrer nofollow"
              className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Tax/Acre Dashboard is Live! <span aria-hidden="true"></span>
            </a>
          </div>
          <div className="mx-auto max-w-2xl py-8 sm:py-16 lg:py-20">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                Bringing Strong Towns to <u>Phoenix, Arizona</u>.
              </h1>
              <p className="mt-6 text-lg leading-8 text-white">
                Our Local Conversation has just begun.
                <br></br>
                Join us in making Phoenix and nearby towns safe, livable, and financially resilient.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a href="https://signup.strongtownsphx.org" target='_blank' rel="noopener noreferrer nofollow"
                  className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Sign up now <span aria-hidden="true">→</span>
                </a>
                <Link
                  to="part-2"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={prefersReducedMotion() ? 0 : 500}
                  className="leading-6 rounded-md bg-gray-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-800"
                  style={{ cursor: 'pointer' }}
                >
                  Learn more
                </Link>
              </div>
              <p className="mt-10">
                <Link
                  to="part-4"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={prefersReducedMotion() ? 0 : 500}
                  className="leading-6 rounded-md bg-gray-800 px-5 py-4 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-800"
                  style={{ cursor: 'pointer' }}
                >
                  Next meeting details
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Element>
      <Element name="part-2" className="element">
        <div className="relative isolate overflow-hidden bg-gray-900 py-16 sm:py-24 lg:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
              <div className="max-w-xl lg:max-w-lg">
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Monthly meetups in the heart of Phoenix.</h2>
                <p className="mt-4 text-lg leading-8 text-gray-300">We&apos;re passionate about making a difference, and you can help us get there. We want to bring the visions of <a href="https://strongtowns.org" target="_blank" rel="noopener noreferrer nofollow"><u><b>Strong Towns</b></u></a> to life.</p>
                <p className="mt-4 text-lg leading-8 text-gray-300"><b>We need your help!</b> It takes a lot of work to do what we&apos;re doing. We can&apos;t do it alone.</p>
                <div className="mt-6 flex max-w-md gap-x-4">
                  <a href="https://signup.strongtownsphx.org" target='_blank' rel="noopener noreferrer nofollow"
                    className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Sign up now <span aria-hidden="true">→</span>
                  </a>
                  <Link
                    to="part-4"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={prefersReducedMotion() ? 0 : 500}
                    className="leading-6 rounded-md bg-gray-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-800"
                    style={{ cursor: 'pointer' }}
                  >
                    Learn more
                  </Link>
                </div>
              </div>
              <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
                <div className="flex flex-col items-start">
                  <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                    <TramSvg className='w-6 text-white' />
                  </div>
                  <dt className="mt-4 font-semibold text-white">Right off the Light Rail</dt>
                  <dd className="mt-2 leading-7 text-gray-400">Our public meetings will always be accessible via Valley Metro.</dd>
                </div>
                <div className="flex flex-col items-start">
                  <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                    <SmileSvg className='w-6 text-white' />
                  </div>
                  <dt className="mt-4 font-semibold text-white">Free and publicly accessible meetings</dt>
                  <dd className="mt-2 leading-7 text-gray-400">Our meetings take place in public facilities and you won&apos;t have to pay a dime to attend.</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </Element>
      <Element name="part-3" className="element">
        <div className="relative isolate overflow-hidden bg-gray-300">
          <MapCentered style={{ minWidth: '100%', minHeight: '500px', maxHeight: '500px' }} mapContainerProps={{ scrollWheelZoom: false, zoom: 10 }} />
        </div>
      </Element>
      <Element name="part-4" className="element">
        <div className="relative isolate overflow-hidden bg-gray-900 py-16 sm:py-24 lg:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
              <div className="max-w-xl lg:max-w-lg">
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Our next monthly meetup is coming up soon!</h2>
                <p className="mt-4 text-lg leading-8 text-gray-300">
                  Meet us <a href="https://discord.strongtownsphx.org" target='_blank' rel="noopener noreferrer nofollow">virtually on Discord</a>, on <b>Saturday, August 3rd, 2024</b>, at <b>1-3pm</b>, in the <b>#meetup channel</b>.
                </p>
                {/* <p className="mt-4 text-lg leading-8 text-gray-300">
                  Meet us at the <a href="https://www.phoenixpubliclibrary.org/locations/burton-barr" target='_blank' rel="noopener noreferrer nofollow">Burton Barr Central Public Library</a> in Phoenix, on <b>Saturday, July 13th, 2024</b>, at <b>230-430pm</b>, in <b>Meeting Room A</b>.
                </p> */}
                <p className="mt-4 text-lg leading-8 text-gray-300">
                  Want to connect with us before our next meeting?
                </p>
                <div className="mt-6 flex max-w-md gap-x-4">
                  <a href="https://discord.strongtownsphx.org"
                    className="leading-6 rounded-md bg-indigo-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    target="_black" rel="noopener noreferrer nofollow"
                  >
                    Join the Conversation on Discord <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
              <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
                <div className="flex flex-col items-start">
                  <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                    <QuestionSvg className='w-4 text-white' />
                  </div>
                  <dt className="mt-4 font-semibold text-white">Is Discord Not Your Thing?</dt>
                  <dd className="mt-2 leading-7 text-gray-400">
                    Discord is currently our primary place for connecting digitally. However, we understand that Discord may not be for everyone. We have other socials that we are still ramping up, such as Instagram, TikTok, X/Twitter, YouTube, Facebook, and others.
                  </dd>
                  <div className="mt-6 flex max-w-md gap-x-4">
                    <a href='https://www.tiktok.com/@strongtownsphx/' target="_blank" rel="noopener nofollow noreferrer">
                      <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                        <TikTokSvg className='social-icon text-white' />
                      </div>
                    </a>
                    <a href='https://www.facebook.com/people/Stong-Towns-Phoenix/61556441207605/' target="_blank" rel="noopener nofollow noreferrer">
                      <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                        <FacebookSvg className='social-icon text-white' />
                      </div>
                    </a>
                    <a href='https://www.instagram.com/strongtownsphx' target="_blank" rel="noopener nofollow noreferrer">
                      <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                        <InstagramSvg className='social-icon text-white' />
                      </div>
                    </a>
                    <a href='https://youtube.com/@strongtownsphx' target="_blank" rel="noopener nofollow noreferrer">
                      <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                        <YouTubeSvg className='social-icon text-white' />
                      </div>
                    </a>
                    <a href='https://twitter.com/strongtownsphx' target="_blank" rel="noopener nofollow noreferrer">
                      <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                        <XTwitterSvg className='social-icon text-white' />
                      </div>
                    </a>
                  </div>
                </div>
                <div className="flex flex-col items-start">
                  <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                    <a href='mailto:contact@strongtownsphx.org' target="_blank" rel="noopener nofollow noreferrer">
                      <EmailSvg className='social-icon text-white' />
                    </a>
                  </div>
                  <dt className="mt-4 font-semibold text-white">Need a more traditional way to stay in touch?</dt>
                  <dd className="mt-2 leading-7 text-gray-400">
                    You can always send us an email. We will typically respond to inquiries within one to two business days.
                  </dd>
                  <div className="mt-6 flex max-w-md gap-x-4">
                    <a href="mailto:contact@strongtownsphx.org" className="rounded-md px-6 bg-white/5 p-2 ring-1 ring-white/10 text-white">
                      Email us <span aria-hidden="true">→</span>
                    </a>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </Element>
    </div>

  );
};

export default SimpleCentered;
